<template>
	<modal class="NoxModalStrategySettings" name="NoxModalStrategySettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'addStrategy'">Добавление торговой стратегии</span>
						<span v-else-if="noxType == 'editStrategy'">Редактирование торговой стратегии</span>
					</div>
				</div>
				<div class="nox_modal_body">
					<form class="nox_modal_form">
						<div class="nox_modal_info">
							<div v-if="['addStrategy', 'editStrategy'].includes(noxType)">
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Тип стратегии:</div>
									<div class="nox_modal_info_row_value">
										<div class="nox_radios">
											<label class="nox_radio" :for="'nox_radio_type_' + index" v-for="(value, index) in noxStrategyTypes" :key="index">
												<input type="radio" :id="'nox_radio_type_' + index" name="nox_radio_type" :value="(index + 1)" v-model.trim="noxStrategyType">
												<span class="nox_radio_mark"></span>
												<span class="nox_radio_text">{{ value }}</span>
											</label>
										</div>
										<div v-html="noxAlertStrategyType"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Название стратегии:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyName" placeholder="название стратегии" autocomplete="off">
										<div v-html="noxAlertStrategyName"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Описание стратегии:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyDescription" placeholder="описание стратегии" autocomplete="off">
										<div v-html="noxAlertStrategyDescription"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Ссылка на описание:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyDescriptionLink" placeholder="ссылка на описание стратегии" autocomplete="off">
										<div v-html="noxAlertStrategyDescriptionLink"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxStrategyType == 1">
									<div class="nox_modal_info_row_label">Min множитель риска:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyMinMultiplier" placeholder="min множитель риска стратегии" autocomplete="off">
										<div v-html="noxAlertStrategyMinMultiplier"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxStrategyType == 1">
									<div class="nox_modal_info_row_label">Max множитель риска:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyMaxMultiplier" placeholder="max множитель риска стратегии" autocomplete="off">
										<div v-html="noxAlertStrategyMaxMultiplier"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxStrategyType == 1">
									<div class="nox_modal_info_row_label">Вероятность копиров.:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyCopyProbability" placeholder="вероятность копирования сигналов" autocomplete="off">
										<div v-html="noxAlertStrategyCopyProbability"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxStrategyType == 1">
									<div class="nox_modal_info_row_label">Мастера:</div>
									<div class="nox_modal_info_row_value">
										<div class="nox_checkboxes">
											<label class="nox_checkbox" :for="'nox_checkbox_master_' + index" v-for="(value, index) in noxMasterSettings" :key="index">
												<input type="checkbox" :id="'nox_checkbox_master_' + index" v-model.trim="noxStrategyMasterStates[index]">
												<span class="nox_checkbox_mark"></span>
												<span class="nox_checkbox_text">
													<span>{{ value.shortname }}</span>
													<span v-if="value.description"> ({{ value.description }})</span>
												</span>
											</label>
										</div>
										<div v-html="noxAlertStrategyMasters"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Тип счета:</div>
									<div class="nox_modal_info_row_value">
										<div class="nox_radios">
											<label class="nox_radio" :for="'nox_radio_type_account_' + index" v-for="(value, index) in noxStrategyTypeAccounts" :key="index">
												<input type="radio" :id="'nox_radio_type_account_' + index" name="nox_radio_type_account" :value="value" v-model.trim="noxStrategyTypeAccount">
												<span class="nox_radio_mark"></span>
												<span class="nox_radio_text">{{ value }}</span>
											</label>
										</div>
										<div v-html="noxAlertStrategyTypeAccount"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Доступна в пакетах:</div>
									<div class="nox_modal_info_row_value">
										<div class="nox_checkboxes">
											<label class="nox_checkbox" :for="'nox_checkbox_package_' + index" v-for="(value, index) in noxStrategyPackages" :key="index">
												<input type="checkbox" :id="'nox_checkbox_package_' + index" v-model.trim="noxStrategyPackageStates[index]">
												<span class="nox_checkbox_mark"></span>
												<span class="nox_checkbox_text">{{ value }}</span>
											</label>
										</div>
										<div v-html="noxAlertStrategyPackages"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxStrategyType == 1">
									<div class="nox_modal_info_row_label">Режим insomnia:</div>
									<div class="nox_modal_info_row_value">
										<toggle-button class="nox_toggle_button" :value="toggleValue(noxStrategyIsInsomnia)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStrategyIsInsomnia"></toggle-button>
										<div v-html="noxAlertStrategyIsInsomnia"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Показывать в каталоге:</div>
									<div class="nox_modal_info_row_value">
										<toggle-button class="nox_toggle_button" :value="toggleValue(noxStrategyIsShowStats)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStrategyIsShowStats"></toggle-button>
										<div v-html="noxAlertStrategyIsShowStats"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Только для NOX ID:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyAvailability" placeholder="список пользователей (пример: 1,10,100)" autocomplete="off">
										<div v-html="noxAlertStrategyAvailability"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Максимум ботов:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStrategyMaxBots" placeholder="максимальное количество ботов" autocomplete="off">
										<div v-html="noxAlertStrategyMaxBots"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="nox_modal_alert" v-html="noxAlert"></div>
						<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
						<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
							<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
							<button type="submit" class="nox_button common green" @click="submit" v-if="noxType == 'addStrategy'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
							<button type="submit" class="nox_button common green" @click="submit" v-else-if="noxType == 'editStrategy'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertStrategyType: '',
			noxAlertStrategyName: '',
			noxAlertStrategyDescription: '',
			noxAlertStrategyDescriptionLink: '',
			noxAlertStrategyMinMultiplier: '',
			noxAlertStrategyMaxMultiplier: '',
			noxAlertStrategyCopyProbability: '',
			noxAlertStrategyTypeAccount: '',
			noxAlertStrategyPackages: '',
			noxAlertStrategyMasters: '',
			noxAlertStrategyMaxBots: '',
			noxAlertStrategyAvailability: '',
			noxAlertStrategyIsInsomnia: '',
			noxAlertStrategyIsShowStats: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxStrategyType: 1,
			noxStrategyTypes: [],
			noxStrategyName: '',
			noxStrategyDescription: '',
			noxStrategyDescriptionLink: '',
			noxStrategyMinMultiplier: '',
			noxStrategyMaxMultiplier: '',
			noxStrategyCopyProbability: '',
			noxStrategyTypeAccount: '',
			noxStrategyTypeAccounts: [],
			noxStrategyPackages: [],
			noxStrategyPackageStates: [],
			noxStrategyPackageValues: [],
			noxStrategyMasters: [],
			noxStrategyMasterStates: [],
			noxStrategyMasterValues: [],
			noxStrategyMaxBots: '',
			noxStrategyAvailability: '',
			noxStrategyIsInsomnia: 0,
			noxStrategyIsShowStats: 0,
			noxMasterSettings: [],
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxStrategyType = 1;
				this.noxStrategyTypes = ['standard', 'phoenix'];
				this.noxStrategyName = '';
				this.noxStrategyDescription = '';
				this.noxStrategyDescriptionLink = '';
				this.noxStrategyMinMultiplier = '';
				this.noxStrategyMaxMultiplier = '';
				this.noxStrategyCopyProbability = '';
				this.noxStrategyTypeAccount = 'demo';
				this.noxStrategyTypeAccounts = ['demo', 'any'];
				this.noxStrategyPackages = [
					this.$store.state.noxSystemSettings['package_name_x0'],
					this.$store.state.noxSystemSettings['package_name_x1'],
					this.$store.state.noxSystemSettings['package_name_x2'],
					this.$store.state.noxSystemSettings['package_name_x3'],
					this.$store.state.noxSystemSettings['package_name_x4'],
					this.$store.state.noxSystemSettings['package_name_x5'],
					this.$store.state.noxSystemSettings['package_name_x6'],
					this.$store.state.noxSystemSettings['package_name_x7'],
					this.$store.state.noxSystemSettings['package_name_x8'],
					this.$store.state.noxSystemSettings['package_name_x9']
				];
				this.noxStrategyPackageStates = [];
				this.noxStrategyPackageValues = [];
				this.noxStrategyMasters = [];
				this.noxStrategyMasterStates = [];
				this.noxStrategyMasterValues = [];
				this.noxStrategyMaxBots = '';
				this.noxStrategyAvailability = '';
				this.noxStrategyIsInsomnia = 0;
				this.noxStrategyIsShowStats = 0;
				this.noxIsOpenLoading = true;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editStrategy') {

					this.noxData = this.$store.state.noxStrategySettings[this.noxIndex];
					this.noxStrategyType = this.noxData.type;
					this.noxStrategyName = this.noxData.name;
					this.noxStrategyDescription = this.noxData.description;
					this.noxStrategyDescriptionLink = this.noxData.description_link;
					this.noxStrategyMinMultiplier = this.noxData.min_multiplier.toFixed(1);
					this.noxStrategyMaxMultiplier = this.noxData.max_multiplier.toFixed(1);
					this.noxStrategyCopyProbability = this.noxData.copy_probability;
					this.noxStrategyTypeAccount = this.noxData.type_account;
					this.noxStrategyMaxBots = this.noxData.max_bots;
					this.noxStrategyAvailability = this.noxData.availability;
					this.noxStrategyIsInsomnia = this.noxData.is_insomnia;
					this.noxStrategyIsShowStats = this.noxData.is_show_stats;
				}

				this.axios('initStrategy');
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertStrategyType = '';
				this.noxAlertStrategyName = '';
				this.noxAlertStrategyDescription = '';
				this.noxAlertStrategyDescriptionLink = '';
				this.noxAlertStrategyMinMultiplier = '';
				this.noxAlertStrategyMaxMultiplier = '';
				this.noxAlertStrategyCopyProbability = '';
				this.noxAlertStrategyTypeAccount = '';
				this.noxAlertStrategyPackages = '';
				this.noxAlertStrategyMasters = '';
				this.noxAlertStrategyMaxBots = '';
				this.noxAlertStrategyAvailability = '';
				this.noxAlertStrategyIsInsomnia = '';
				this.noxAlertStrategyIsShowStats = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id стратегии.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id стратегии.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать тип стратегии.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный тип стратегии.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать название стратегии.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное название стратегии.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать описание стратегии.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верное описание стратегии.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать ссылку на описание стратегии.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верную ссылку на описание стратегии.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать минимальный множитель риска стратегии.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный минимальный множитель риска стратегии.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать максимальный множитель риска стратегии.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный максимальный множитель риска стратегии.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать вероятность копирования сигналов стратегии.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верную вероятность копирования сигналов стратегии.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо выбрать хотя бы одного мастера стратегии.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо выбрать хотя бы одного верного мастера стратегии.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо выбрать тип счета стратегии.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо выбрать верный тип счета стратегии.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо выбрать хотя бы один пакет стратегии.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо выбрать хотя бы один верный пакет стратегии.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать статус бессонницы (Insomnia) стратегии.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верный статус бессонницы (Insomnia) стратегии.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать статус показа в каталоге стратегии.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать верный статус показа в каталоге стратегии.'; }
				else if (i == 27) { this.noxTemp = 'Необходимо указать список пользователей для доступа к стратегии.'; }
				else if (i == 28) { this.noxTemp = 'Необходимо указать верный список пользователей для доступа к стратегии.'; }
				else if (i == 29) { this.noxTemp = 'Необходимо указать максимальное количество ботов стратегии.'; }
				else if (i == 30) { this.noxTemp = 'Необходимо указать верное максимальное количество ботов стратегии.'; }
				else if (i == 31) { this.noxTemp = 'Id стратегии не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event) {
				event.preventDefault(); this.axios();
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxStrategyMasterValues = [];
					_this.noxStrategyPackageValues = [];
					_this.noxIsError = false;

					if (type == 'initStrategy') {
						config.url = '/v2/admin/account/bots/masters/only_masters';
					}
					else if (['addStrategy', 'editStrategy'].includes(_this.noxType)) {

						for (var i in _this.noxStrategyMasterStates) {
							if (_this.noxStrategyMasterStates[i] === true) { _this.noxStrategyMasterValues.push(Number(_this.noxMasterSettings[i].id)); }
						}
						for (var j in _this.noxStrategyPackageStates) {
							if (_this.noxStrategyPackageStates[j] === true) { _this.noxStrategyPackageValues.push(Number(j)); }
						}

						if (!_this.noxStrategyName) {
							_this.noxAlertStrategyName = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxStrategyName)) {
							_this.noxAlertStrategyName = _this.getError(6);
						}
						if (_this.noxStrategyDescription && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxStrategyDescription)) {
							_this.noxAlertStrategyDescription = _this.getError(8);
						}
						if (_this.noxStrategyDescriptionLink && !_this.$store.state.noxRegexAnyLink.test(_this.noxStrategyDescriptionLink)) {
							_this.noxAlertStrategyDescriptionLink = _this.getError(10);
						}
						if (_this.noxStrategyType == 1) {
							if (!_this.noxStrategyMinMultiplier) {
								_this.noxAlertStrategyMinMultiplier = _this.getError(11);
							}
							else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxStrategyMinMultiplier)) {
								_this.noxAlertStrategyMinMultiplier = _this.getError(12);
							}
							if (!_this.noxStrategyMaxMultiplier) {
								_this.noxAlertStrategyMaxMultiplier = _this.getError(13);
							}
							else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxStrategyMaxMultiplier)) {
								_this.noxAlertStrategyMaxMultiplier = _this.getError(14);
							}
							if (!_this.noxStrategyCopyProbability) {
								_this.noxAlertStrategyCopyProbability = _this.getError(15);
							}
							else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxStrategyCopyProbability)) {
								_this.noxAlertStrategyCopyProbability = _this.getError(16);
							}
							if (!_this.noxStrategyMasterValues.length) {
								_this.noxAlertStrategyMasters = _this.getError(17);
							}
						}
						if (_this.noxStrategyAvailability) {
							var availability = String(_this.noxStrategyAvailability).split(',');
							if (availability.length) {
								for (var a in availability) {
									if ((availability[a] ^ 0) !== Number(availability[a]) || Number(availability[a]) < 1) {
										_this.noxAlertStrategyAvailability = _this.getError(28); break;
									}
								}
							}
							else {
								_this.noxAlertStrategyAvailability = _this.getError(28);
							}
						}
						if (!_this.noxStrategyMaxBots) {
							_this.noxAlertStrategyMaxBots = _this.getError(29);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxStrategyMaxBots) || _this.noxStrategyMaxBots < 1) {
							_this.noxAlertStrategyMaxBots = _this.getError(30);
						}
						if (!_this.noxIsError) {
							if (_this.noxStrategyType == 2) {
								_this.noxStrategyMinMultiplier = 1;
								_this.noxStrategyMaxMultiplier = 1;
								_this.noxStrategyCopyProbability = 1;
								_this.noxStrategyMasterValues = [0];
								_this.noxStrategyIsInsomnia = 0;
							}
							config.url = '/v2/admin/account/bots/strategies';
							config.data = { id: _this.noxId, type: _this.noxStrategyType, name: _this.noxStrategyName, description: _this.noxStrategyDescription, description_link: _this.noxStrategyDescriptionLink, min_multiplier: Number(_this.noxStrategyMinMultiplier), max_multiplier: Number(_this.noxStrategyMaxMultiplier), copy_probability: _this.noxStrategyCopyProbability, type_account: _this.noxStrategyTypeAccount, packages: _this.noxStrategyPackageValues.join(','), masters: _this.noxStrategyMasterValues.join(','), max_bots: _this.noxStrategyMaxBots, availability: _this.noxStrategyAvailability, is_insomnia: _this.noxStrategyIsInsomnia, is_show_stats: _this.noxStrategyIsShowStats };
							config.method = _this.noxType == 'addStrategy' ? 'post' : 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'initStrategy') {
									_this.noxMasterSettings = data.data;
									if (_this.$store.state.noxStrategySettings[_this.noxIndex] !== undefined) {
										for (var i in _this.noxMasterSettings) {
											_this.noxStrategyMasterStates[i] = _this.$store.state.noxStrategySettings[_this.noxIndex].masters.indexOf(String(_this.noxMasterSettings[i].id)) >= 0 ? true : false;
										}
										for (var j in _this.noxStrategyPackages) {
											_this.noxStrategyPackageStates[j] = _this.$store.state.noxStrategySettings[_this.noxIndex].packages.indexOf(String(j)) >= 0 ? true : false;
										}
									}
								}
								else if (['addStrategy', 'editStrategy'].includes(_this.noxType)) {
									data.data.masters = String(data.data.masters).split(',');
									data.data.packages = String(data.data.packages).split(',');
									if (_this.noxType == 'addStrategy') {
										_this.$store.state.noxStrategySettings.push(data.data);
									}
									else if (_this.noxType == 'editStrategy') {
										_this.$store.state.noxStrategySettings.splice(_this.noxIndex, 1, data.data);
									}
									_this.close();
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addStrategy', 'editStrategy'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertStrategyType = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertStrategyType = _this.getError(4); }
										else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertStrategyName = _this.getError(5); }
										else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertStrategyName = _this.getError(6); }
										else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertStrategyDescription = _this.getError(7); }
										else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertStrategyDescription = _this.getError(8); }
										else if (data.response.data.error == 'DESCRIPTION_LINK_IS_EMPTY') { _this.noxAlertStrategyDescriptionLink = _this.getError(9); }
										else if (data.response.data.error == 'DESCRIPTION_LINK_NOT_VALID') { _this.noxAlertStrategyDescriptionLink = _this.getError(10); }
										else if (data.response.data.error == 'MIN_MULTIPLIER_IS_EMPTY') { _this.noxAlertStrategyMinMultiplier = _this.getError(11); }
										else if (data.response.data.error == 'MIN_MULTIPLIER_NOT_VALID') { _this.noxAlertStrategyMinMultiplier = _this.getError(12); }
										else if (data.response.data.error == 'MAX_MULTIPLIER_IS_EMPTY') { _this.noxAlertStrategyMaxMultiplier = _this.getError(13); }
										else if (data.response.data.error == 'MAX_MULTIPLIER_NOT_VALID') { _this.noxAlertStrategyMaxMultiplier = _this.getError(14); }
										else if (data.response.data.error == 'COPY_PROBABILITY_IS_EMPTY') { _this.noxAlertStrategyCopyProbability = _this.getError(15); }
										else if (data.response.data.error == 'COPY_PROBABILITY_NOT_VALID') { _this.noxAlertStrategyCopyProbability = _this.getError(16); }
										else if (data.response.data.error == 'MASTERS_IS_EMPTY') { _this.noxAlertStrategyMasters = _this.getError(17); }
										else if (data.response.data.error == 'MASTERS_NOT_VALID') { _this.noxAlertStrategyMasters = _this.getError(18); }
										else if (data.response.data.error == 'TYPE_ACCOUNT_IS_EMPTY') { _this.noxAlertStrategyTypeAccount = _this.getError(19); }
										else if (data.response.data.error == 'TYPE_ACCOUNT_NOT_VALID') { _this.noxAlertStrategyTypeAccount = _this.getError(20); }
										else if (data.response.data.error == 'PACKAGES_IS_EMPTY') { _this.noxAlertStrategyPackages = _this.getError(21); }
										else if (data.response.data.error == 'PACKAGES_NOT_VALID') { _this.noxAlertStrategyPackages = _this.getError(22); }
										else if (data.response.data.error == 'IS_INSOMNIA_IS_EMPTY') { _this.noxAlertStrategyIsInsomnia = _this.getError(23); }
										else if (data.response.data.error == 'IS_INSOMNIA_NOT_VALID') { _this.noxAlertStrategyIsInsomnia = _this.getError(24); }
										else if (data.response.data.error == 'IS_SHOW_STATS_IS_EMPTY') { _this.noxAlertStrategyIsShowStats = _this.getError(25); }
										else if (data.response.data.error == 'IS_SHOW_STATS_NOT_VALID') { _this.noxAlertStrategyIsShowStats = _this.getError(26); }
										else if (data.response.data.error == 'AVAILABILITY_IS_EMPTY') { _this.noxAlertStrategyAvailability = _this.getError(27); }
										else if (data.response.data.error == 'AVAILABILITY_NOT_VALID') { _this.noxAlertStrategyAvailability = _this.getError(28); }
										else if (data.response.data.error == 'MAX_BOTS_IS_EMPTY') { _this.noxAlertStrategyMaxBots = _this.getError(29); }
										else if (data.response.data.error == 'MAX_BOTS_NOT_VALID') { _this.noxAlertStrategyMaxBots = _this.getError(30); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'editStrategy') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(31); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStrategyIsInsomnia: function(event) {
				this.noxStrategyIsInsomnia = Number(event.value);
			},
			toggleChangeForStrategyIsShowStats: function(event) {
				this.noxStrategyIsShowStats = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalStrategySettings');
			}
		}
	}
</script>
